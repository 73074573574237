import { IconButton } from "@material-ui/core";
import TooltipLight from "Components/TooltipLight";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import taxTypePayment from "models/taxTypePayment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RxPencil2 } from "react-icons/rx";

import ModalSave from "../ModalSave";
import { BackgroundInput, Button, Container, Line } from "./styles";

interface PropsEditTable {
	item: any;
	editItem: (typeId: string, value: any) => void;
	disabledEdit?: boolean;
	cnpj?: string;
	businessName?: string;
	isSelect?: boolean;
	isBag?: boolean;
	saveDataTax?: (item: any) => void;
	returnTypeTax?: (id: string) => any;
	value: number | string | any;
}

const EditLine: React.FC<PropsEditTable> = ({
	item,
	businessName,
	disabledEdit,
	cnpj,
	isBag,
	isSelect,
	editItem,
	saveDataTax,
	returnTypeTax,
	value
}) => {
	const [editable, setEditable] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [valueEdit, setValueEdit] = useState<any>();

	const idItem = useMemo(
		() => item.prePaidBalanceAccountTypeId ?? Object.keys(item)[0],
		[item]
	);

	const handleCloseModal = useCallback(() => {
		setOpenModal(false);
	}, []);

	const saveDataModal = useCallback(() => {
		if (saveDataTax) {
			const item = {
				name: idItem,
				value: Number(valueEdit || value),
				paymentType: String(returnTypeTax(idItem))
			};
			saveDataTax(item);
		}
		handleCloseModal();
	}, [handleCloseModal, idItem, returnTypeTax, saveDataTax, value, valueEdit]);

	const handleOpenModal = useCallback(() => {
		setOpenModal(true);
	}, []);

	const formatValue = useMemo(() => {
		const typeTax = returnTypeTax ? returnTypeTax(idItem) : undefined;
		if (typeTax === 0 || idItem === "inactivityFee") {
			return toStringCurrency(value / 100);
		}

		return `${Number(value) / 100} %`;
	}, [idItem, returnTypeTax, value]);

	const okClick = useCallback(() => {
		if (!isBag) {
			handleOpenModal();
		}

		setEditable(false);
	}, [handleOpenModal, isBag]);

	const returnTypeValue = useMemo(() => {
		if (isSelect) {
			return (
				<select
					disabled={!editable}
					onChange={event => {
						editItem(`${idItem}Type`, event.target.value);
					}}
					style={{
						width: "80%",
						border: "none",
						background: "none",
						height: 20
					}}
					value={returnTypeTax(idItem)}
				>
					{Object.keys(taxTypePayment).map(item => (
						<option value={item} key={item}>
							{ls[taxTypePayment[item]]}
						</option>
					))}
				</select>
			);
		}

		return (
			<Line
				value={editable ? value : formatValue}
				disabled={!editable}
				isDisable={!editable}
				onKeyPress={e => {
					e.key === "Enter" && okClick();
				}}
				onChange={e => {
					setValueEdit(e.target.value);
					editItem(idItem, e.target.value);
				}}
			/>
		);
	}, [
		isSelect,
		editable,
		value,
		formatValue,
		returnTypeTax,
		idItem,
		editItem,
		okClick
	]);

	return (
		<Container>
			<BackgroundInput>
				{returnTypeValue}
				{!disabledEdit && (
					<div style={{ width: "10%" }}>
						{editable ? (
							<Button onClick={okClick}>OK</Button>
						) : (
							<TooltipLight title="Editar Taxa" placement="top">
								<IconButton
									style={{ padding: 4 }}
									onClick={() => setEditable(true)}
								>
									<RxPencil2 size={18} />
								</IconButton>
							</TooltipLight>
						)}
					</div>
				)}
			</BackgroundInput>
			<ModalSave
				businessName={businessName}
				cnpj={cnpj}
				saveData={saveDataModal}
				open={openModal}
				handleClose={handleCloseModal}
			/>
		</Container>
	);
};

export default EditLine;
