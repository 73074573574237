import dayjs from "dayjs";
import * as actions from "store/actions/requestCards/create";

import { IInitialState } from "../types";

const initialState: IInitialState = {
	request: [
		{
			business: {
				cnpj: ""
			},
			quantityCard: 0,
			street: "",
			document: "",
			zipcode: "",
			name: "",
			number: 0,
			neighborhood: "",
			complement: "",
			city: "",
			state: "",
			phone: "",
			email: ""
		}
	],
	cnpj: "",
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_REQUEST_CARDS: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_REQUEST_CARDS_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.CREATE_REQUEST_CARDS_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState,
				creationDate: dayjs()
			};
		default: {
			return state;
		}
	}
};

export default reducer;
