import { Grid } from "@material-ui/core";
import { CPFCNPJ, maskPhone } from "Components/NumberFormat";
import Select from "Components/Select";
import TextInput from "Components/TextInput";
import TextInputGeneric from "Components/TextInputGeneric";
import ls from "Localization";
import serviceStatus from "models/serviceStatus";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { State } from "store/reducers";
import { IInitialState, requestItems } from "store/reducers/requestCards/types";

import { BackgroundCheckBox, Column, Container, Row } from "./styles";

interface PropsForm {
	item: requestItems;
	index: number;
	onChange: (index: number, id: string, value: any) => void;
}

const Form: React.FC<PropsForm> = ({ item, onChange, index }) => {
	const {
		name,
		city,
		complement,
		document,
		neighborhood,
		email,
		number,
		phone,
		street,
		quantityCard,
		state,
		zipcode,
		service,
		business
	} = item;

	const [sn, setSn] = useState(false);

	return (
		<Container>
			<Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
				<Column>
					{/* businessIdd */}
					<p>CNPJ da empresa</p>
					<TextInput
						name="empty"
						id="cnpj"
						variant="outlined"
						placeholder="CNPJ"
						InputProps={{
							disableUnderline: true,
							style: {
								width: 433,
								height: 40,
								backgroundColor: "#fff",
								borderBlockColor: "#707070",
								borderStyle: "solid",
								borderWidth: 1,
								borderRadius: 6,
								marginTop: 10
							},
							inputComponent: CPFCNPJ
						}}
						onChange={(id, value) => {
							onChange(index, id, value);
						}}
						value={business?.cnpj}
						size="small"
					/>
				</Column>
				<Column style={{ marginLeft: 20 }}>
					<p>Quantidade de cartões</p>
					<Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
						<TextInput
							name="empty"
							id="quantityCard"
							variant="outlined"
							InputProps={{
								disableUnderline: true,
								style: {
									width: 100,
									height: 40,
									backgroundColor: "#fff",
									borderBlockColor: "#707070",
									borderStyle: "solid",
									borderWidth: 1,
									borderRadius: 6,
									marginRight: 10,
									marginTop: 10
								}
							}}
							onChange={(id, value) => onChange(index, id, value)}
							value={quantityCard}
							size="small"
						/>
						<p>unidades</p>
					</Grid>
				</Column>

				<Column style={{ marginLeft: 20 }}>
					<p>Tipo de serviço</p>
					<Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
						<Select
							id="service"
							value={service}
							style={{ marginTop: 10 }}
							onChange={(_, v) => onChange(index, _, v)}
							options={Object.keys(serviceStatus).map(c => {
								return {
									id: c,
									value: ls[serviceStatus[c]]
								};
							})}
						/>
					</Grid>
				</Column>
			</Grid>

			<Column style={{ paddingBottom: 30 }}>
				<h4>Dados para entrega</h4>
				<Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
					<Column>
						<p>Destinatário</p>
						<TextInputGeneric
							id="name"
							variant="outlined"
							style={{
								width: 445,
								height: 40,
								backgroundColor: "#fff",
								borderBlockColor: "#707070",
								borderStyle: "solid",
								borderWidth: 1,
								borderRadius: 6,
								marginTop: 10
							}}
							inputProps={{
								disableUnderline: true,
								maxLength: 70
							}}
							onChange={(id, value) => onChange(index, id, value)}
							value={name}
							size="small"
						/>
					</Column>
					<Column style={{ marginLeft: 20 }}>
						<p>CPF ou CNPJ do destinatário</p>
						<TextInput
							id="document"
							name="empty"
							variant="outlined"
							InputProps={{
								disableUnderline: true,
								style: {
									maxWidth: 445,
									height: 40,
									backgroundColor: "#fff",
									borderBlockColor: "#707070",
									borderStyle: "solid",
									borderWidth: 1,
									borderRadius: 6,
									marginTop: 10
								}
							}}
							onChange={(id, value) => onChange(index, id, value)}
							value={document}
							size="small"
						/>
					</Column>
				</Grid>
				<Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
					<Column>
						<p>CEP</p>
						<TextInputGeneric
							id="zipcode"
							variant="outlined"
							InputProps={{
								disableUnderline: true,
								style: {
									width: 200,
									height: 40,
									backgroundColor: "#fff",
									borderBlockColor: "#707070",
									borderStyle: "solid",
									borderWidth: 1,
									borderRadius: 6,
									marginTop: 10
								}
							}}
							onChange={(id, value) =>
								onChange(index, id, value.replace(/\D/g, ""))
							}
							value={zipcode}
							size="small"
						/>
					</Column>
					<Column style={{ marginLeft: 20 }}>
						<p>Logradouro</p>
						<TextInputGeneric
							id="street"
							variant="outlined"
							style={{
								width: 250,
								height: 40,
								backgroundColor: "#fff",
								borderBlockColor: "#707070",
								borderStyle: "solid",
								borderWidth: 1,
								borderRadius: 6,
								marginTop: 10
							}}
							inputProps={{
								disableUnderline: true,
								maxLength: 50
							}}
							onChange={(id, value) => onChange(index, id, value)}
							value={street}
							size="small"
						/>
					</Column>
					<Column style={{ marginLeft: 20 }}>
						<p>Número</p>
						<Row>
							<TextInputGeneric
								id="number"
								variant="outlined"
								type="number"
								InputProps={{
									disableUnderline: true,
									style: {
										width: 150,
										height: 40,
										backgroundColor: "#fff",
										borderBlockColor: "#707070",
										borderStyle: "solid",
										borderWidth: 1,
										borderRadius: 6,
										marginTop: 10
									}
								}}
								onChange={(id, value) => onChange(index, id, value)}
								disabled={sn}
								value={number}
								size="small"
							/>
							<BackgroundCheckBox>
								<input
									type="checkbox"
									placeholder="Selecione uma das opções"
									style={{ marginRight: 10, marginLeft: 20, marginTop: 23 }}
									checked={sn}
									onChange={() => {
										onChange(index, "number", 0);
										setSn(!sn);
									}}
								/>
								<p>{ls.notNumber}</p>
							</BackgroundCheckBox>
						</Row>
					</Column>

					<Column style={{ marginLeft: 20 }}>
						<p>Complemento</p>
						<TextInputGeneric
							id="complement"
							variant="outlined"
							style={{
								maxWidth: 250,
								height: 40,
								backgroundColor: "#fff",
								borderBlockColor: "#707070",
								borderStyle: "solid",
								borderWidth: 1,
								borderRadius: 6,
								marginTop: 10
							}}
							inputProps={{
								maxLength: 30,
								disableUnderline: true
							}}
							onChange={(id, value) => onChange(index, id, value)}
							value={complement}
							size="small"
						/>
					</Column>
				</Grid>
				<Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
					<Column>
						<p>Cidade</p>
						<TextInputGeneric
							id="city"
							variant="outlined"
							style={{
								width: 178,
								height: 40,
								backgroundColor: "#fff",
								borderBlockColor: "#707070",
								borderStyle: "solid",
								borderWidth: 1,
								borderRadius: 6,
								marginTop: 10
							}}
							inputProps={{
								disableUnderline: true,
								maxLength: 30
							}}
							onChange={(id, value) => onChange(index, id, value)}
							value={city}
							size="small"
						/>
					</Column>
					<Column>
						<p style={{ marginLeft: 20 }}>Bairro</p>
						<TextInputGeneric
							id="neighborhood"
							variant="outlined"
							style={{
								width: 178,
								height: 40,
								backgroundColor: "#fff",
								borderBlockColor: "#707070",
								borderStyle: "solid",
								borderWidth: 1,
								borderRadius: 6,
								marginTop: 10,
								marginLeft: 20
							}}
							inputProps={{
								maxLength: 30,
								disableUnderline: true
							}}
							onChange={(id, value) => onChange(index, id, value)}
							value={neighborhood}
							size="small"
						/>
					</Column>
					<Column style={{ marginLeft: 20 }}>
						<p>Estado</p>
						<TextInputGeneric
							id="state"
							variant="outlined"
							style={{
								width: 122,
								height: 40,
								backgroundColor: "#fff",
								borderBlockColor: "#707070",
								borderStyle: "solid",
								borderWidth: 1,
								borderRadius: 6,
								marginTop: 10
							}}
							inputProps={{
								disableUnderline: true,
								maxLength: 2
							}}
							onChange={(id, value) => onChange(index, id, value)}
							value={state}
							size="small"
						/>
					</Column>
					<Column style={{ marginLeft: 20 }}>
						<p>Telefone</p>
						<Row>
							<div style={{ marginLeft: 5 }}>
								<TextInputGeneric
									id="phone"
									variant="outlined"
									InputProps={{
										disableUnderline: true,
										style: {
											width: 229,
											height: 40,
											backgroundColor: "#fff",
											borderBlockColor: "#707070",
											borderStyle: "solid",
											borderWidth: 1,
											borderRadius: 6,
											marginTop: 10
										}
									}}
									onChange={(id, value) => {
										onChange(index, id, value.replace(/\D/g, ""));
									}}
									value={phone}
									size="small"
								/>
							</div>
						</Row>
					</Column>
					<Column style={{ marginLeft: 20 }}>
						<p>E-mail</p>
						<TextInputGeneric
							id="email"
							variant="outlined"
							InputProps={{
								disableUnderline: true,
								style: {
									maxWidth: 250,
									height: 40,
									backgroundColor: "#fff",
									borderBlockColor: "#707070",
									borderStyle: "solid",
									borderWidth: 1,
									borderRadius: 6,
									marginTop: 10
								}
							}}
							onChange={(id, value) => onChange(index, id, value)}
							value={email}
							size="small"
						/>
					</Column>
				</Grid>
			</Column>
		</Container>
	);
};

export default Form;
