import { isLogged } from "helpers/auth";
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { submitLoginByPlatformWiipo } from "store/actions/login";
import * as loginActions from "store/actions/login";

interface PlatformWiipoContextProps {
	isPlatformWiipo: boolean;
}

export const PlatformWiipoContext = createContext<PlatformWiipoContextProps>(
	{} as PlatformWiipoContextProps
);

interface PlatformWiipoProviderProps {
	children: React.ReactNode;
}

export const PlatformWiipoProvider = ({
	children
}: PlatformWiipoProviderProps) => {
	const isPlatformWiipo = window.self !== window.top;
	const history = useHistory();
	const dispatch = useDispatch();

	const {login, user} = useSelector((s: any) => {
    return {login: s.login, user: s.userCreate}
  });

  const pathsMaps = {
    "/Company/my_company": user?.businessId ? `/Company/${user.businessId}` : "/Company"
  }

	let loggedIn = isLogged(login);

	useEffect(() => {
		const handleMessage = event => {
			if (event.data.type === "NAVIGATE") {
				const path = pathsMaps[event.data.url] || event.data.url
				history.push(path);
				event.source.postMessage(
					{ type: "NAVIGATE_SUCCESS", url: path  },
					event.origin
				);
			}

			if (event.data.type === "LOGOUT") {
				// Executa a lógica de logout
				dispatch(loginActions.logoutUser());

				setTimeout(() => {
					event.source.postMessage(
						{ type: "LOGOUT_SUCCESS", messageId: event.data.messageId },
						event.origin
					);
				}, 500);
			}

			if (event.data.type === "LOGIN") {
        dispatch(submitLoginByPlatformWiipo(event.data.data));
			}
		};

		// Adiciona o listener para receber mensagens
		window.addEventListener("message", handleMessage);
		// Cleanup para evitar múltiplos listeners ao desmontar o componente
		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, []);

	return (
		<PlatformWiipoContext.Provider value={{ isPlatformWiipo }}>
			{children}
		</PlatformWiipoContext.Provider>
	);
};

export const usePlatformWiipo = () => {
	const context = React.useContext(PlatformWiipoContext);
	if (context === undefined) {
		throw new Error(
			"usePlatformWiipo must be used within a PlatformWiipoProvider"
		);
	}
	return context;
};
