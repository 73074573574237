import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { PDFExport } from "@progress/kendo-react-pdf";
import history from "AppHistory";
import Spinner from "Components/Spinner";
import TooltipLight from "Components/TooltipLight";
import { toastError } from "helpers/toast";
import _ from "lodash";
import React, { useCallback, useEffect, useRef } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaFilePdf } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getRequestCards } from "store/actions/requestCards/detail";
import { State } from "store/reducers";
import {
	IInitialState,
	IRequestDetailState
} from "store/reducers/requestCards/types";

import { RowGray } from "../Add/Detail/Request/styles";
import Request from "./Request";
import { Header } from "./styles";
import TemplatePDF from "./TemplatePDF";

let getCardDeliveryIdDebounced: (() => void) & _.Cancelable = null;

const Detail: React.FC = () => {
	const dispatch = useDispatch();
	const goBack = useCallback(() => {
		history.push("/RequestCards");
	}, []);

	const pdfExportComponent = useRef(null);

	const handleDownloadPDF = () => {
		if (pdfExportComponent.current) {
			pdfExportComponent.current.save();
		}
	};

	const { id } = useParams<any>();

	const handleFetchCardDelivery = () => {
		if (getCardDeliveryIdDebounced) {
			getCardDeliveryIdDebounced.cancel();
		}

		getCardDeliveryIdDebounced = _.debounce(
			() =>
				dispatch(
					getRequestCards(id, err => {
						if (err) {
							toastError(JSON.stringify(err));
						}
					})
				),
			500
		);

		getCardDeliveryIdDebounced();
	};

	useEffect(() => {
		handleFetchCardDelivery();
	}, [id]);

	const { request, loading } = useSelector<State, IRequestDetailState>(
		s => s.requestDetail
	);

	if (loading.get) {
		return (
			<div>
				<Spinner color="secondary" size={16} />
			</div>
		);
	}

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper>
					<Header>
						<Typography variant="h5">
							<AiOutlineArrowLeft
								size={18}
								style={{ marginRight: 10, cursor: "pointer" }}
								onClick={() => goBack()}
							/>{" "}
							Minhas Solicitações
						</Typography>
						<TooltipLight title="Exportar pedido" placement="top">
							<IconButton onClick={handleDownloadPDF}>
								<FaFilePdf />
							</IconButton>
						</TooltipLight>
						<div style={{ position: "absolute", left: -9999 }}>
							<PDFExport
								ref={pdfExportComponent}
								paperSize="A4"
								fileName={`Pedido_${id}`}
							>
								<TemplatePDF item={request} id={id} />
							</PDFExport>
						</div>
					</Header>
					<RowGray style={{ height: 2 }} />
					<Request item={request} id={id} />
				</Paper>
			</Grid>
		</Grid>
	);
};

export default Detail;
