import React from "react";
import { requestItems } from "store/reducers/requestCards/types";

import {
	Container,
	RowGray,
	TextAdress,
	TextDestiny,
	TextQuantity,
	Title
} from "./styles";

interface iProps {
	item: requestItems[];
}
const Request: React.FC<iProps> = ({ item }) => {
	return (
		<>
			{item.map((i, index) => {
				const {
					quantityCard,
					document,
					name,
					city,
					state,
					zipcode,
					complement,
					neighborhood,
					number,
					street,
					phone,
					business
				} = i;

				return (
					<Container>
						<Title> Pedido #{index + 1} </Title>
						<TextQuantity>
							{quantityCard} unidades cartão Wiipo Flex para o cnpj{" "}
							{business?.cnpj}
						</TextQuantity>
						<TextAdress>
							Endereço pra entrega: {street} - {city},{state},{neighborhood} -{" "}
							{number} CEP {zipcode}
						</TextAdress>
						<TextDestiny>
							Destinatário: {name}, CPF {document}, Telefone {phone}{" "}
							{complement ? "," + complement : ""}
						</TextDestiny>
					</Container>
				);
			})}

			<RowGray />
		</>
	);
};

export default Request;
