import { PlatformWiipoProvider, usePlatformWiipo } from "contexts/PlatformWiipo";
import * as H from "history";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { isLogged } from "../../helpers/auth";
import ChangePassword from "../ChangePassword";
import Dashboard from "../Dashboard";
import Login from "../Login";
import RecoverPassword from "../RecoverPassword";
import Cookie from 'js-cookie';

interface Props {
	history: H.History<any>;
}

function AppRouter({ history }: Props) {
	const login = useSelector((s: any) => s.login);
  const { isPlatformWiipo } = usePlatformWiipo()

	let loggedIn = isLogged(login);

  const {pathname, search } = window.location

  let path = pathname || '';
  path += search || '';

  const encodeURI = encodeURIComponent(path)
  const decodeURI = decodeURIComponent(pathname + search).replace(
    /\/Login\?redirectTo=/g,
    ''
  );

  const platformLastPath = Cookie.get("_platform_wiipo_admin_flex_last_pathname")

	return (
		<Router history={history}>
			<PlatformWiipoProvider>
				<Switch>
					<Route
						exact
						path="/Login"
						render={() => {
              if(loggedIn) {

                if(decodeURI && decodeURI !== "/Login") {
                  return <Redirect to={decodeURI} />
                }

                if(isPlatformWiipo && platformLastPath) {
                  return <Redirect to={platformLastPath} />
                }

                return <Redirect to={"/"} />
              }

              return <Login />
            }}
					/>
					<Route
						exact
						path="/RecoverPassword"
						render={() =>
							loggedIn ? <Redirect to="/" /> : <RecoverPassword />
						}
					/>
					<Route
						exact
						path="/ChangePassword"
						render={() => (loggedIn ? <Redirect to="/" /> : <ChangePassword />)}
					/>
					<Route
						path="/"
						render={() =>
							loggedIn ? (
								<Dashboard history={history} />
							) : (
								<Redirect to={`/Login?redirectTo=${encodeURI}`} />
							)
						}
					/>
					<Redirect to={`/Login?redirectTo=${encodeURI}`} />
				</Switch>
			</PlatformWiipoProvider>
		</Router>
	);
}

export default AppRouter;
