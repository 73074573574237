import { Grid, Paper, Typography } from "@material-ui/core";
import history from "AppHistory";
import { exportExcelGenerate } from "Components/Report/export";
import Spinner from "Components/Spinner";
import Tooltip from "Components/Tooltip";
import Form from "Components/views/Dashboard/WiipoFlex/RequestCards/Form";
import { removeDiacritics } from "helpers/string";
import { toastError } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { CgTemplate } from "react-icons/cg";
import { FaPlusCircle } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { MdLastPage } from "react-icons/md";
import { SiGooglesheets } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setValue } from "store/actions/requestCards/create";
import { State } from "store/reducers";
import { IInitialState, requestItems } from "store/reducers/requestCards/types";
import XLSX from "xlsx";

import {
	BackgroundHeader,
	BackgroundIcon,
	Body,
	ButtonRequest,
	Footer,
	Header,
	RowIcons,
	Title
} from "./styles";

interface iModel {
	business: {
		cnpj: string;
	};
	quantityCard: number;
	street: string;
	document: string;
	zipcode: string;
	name: string;
	number: number;
	neighborhood: string;
	complement: string;
	city: string;
	state: string;
	phone: string;
	email: string;
	service: string;
}

let getBusinessDebounced: (() => void) & _.Cancelable = null;

const Add: React.FC = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const { request } = useSelector<State, IInitialState>(s => s.requestCreate);
	const { items } = useSelector<State, IInitialState>(s => s.requestReport);
	const location = useLocation();
	const inputFile = useRef<HTMLInputElement>(null);

	const openSelectFile = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};

	const backToReport = useCallback(() => {
		history.push(location.pathname.replace("/Add", ""));
	}, [location.pathname]);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const lastRequest = useCallback(() => {
		const lastItem: requestItems = {
			business: {
				cnpj: items[0]?.business?.cnpj
			},
			quantityCard: items[0].quantityCard,
			street: items[0].street,
			zipcode: items[0].zipcode,
			name: items[0].name,
			state: items[0].state,
			service: items[0].service,
			phone: items[0].phone,
			city: items[0].city,
			complement: items[0].complement,
			document: items[0].document,
			email: items[0].email,
			number: items[0].number,
			neighborhood: items[0].neighborhood
		};

		handleChange("request", [lastItem]);
	}, [handleChange, items]);

	const handleFileSelected = e => {
		const file = e.target.files[0];
		if (!file) return;

		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = e => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
			/* Get first worksheet */
			const sheetName = wb.SheetNames[0];

			const sheet = wb.Sheets[sheetName];

			let headers = [];
			let range = XLSX.utils.decode_range(sheet["!ref"]);
			let C;
			let R = range.s.r;
			for (C = range.s.c; C <= range.e.c; ++C) {
				let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];

				if (cell && cell.t === "s") {
					cell.v = removeDiacritics(cell.v.trim().toLowerCase());
					if (cell.w) cell.w = removeDiacritics(cell.w.trim().toLowerCase());

					headers.push(removeDiacritics(cell.v.trim().toLowerCase()));
				}
			}

			const data = XLSX.utils.sheet_to_json(sheet);
			const filterData = data.map(i => {
				const model: iModel = {
					quantityCard: i["quantidade de cartoes"],
					name: i["destinatario"],
					document: i["cpf ou cnpj do destinatario"].replace(/\D/g, ""),
					zipcode: i["cep"],
					street: i["logradouro"],
					number: i["numero"],
					complement: i["complemento"],
					city: i["cidade"],
					state: i["estado"],
					phone: i["telefone"],
					email: i["email"],
					neighborhood: i["bairro"],
					service: i["tipo de servico"],
					business: {
						cnpj: removeDiacritics(i["cnpj da empresa"]).replace(/\D/g, "")
					}
				};

				return model;
			}, []);
			handleChange("request", filterData);
		};

		if (rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);

		inputFile.current.value = null;
	};

	const isLoading = useCallback((boolean: boolean) => {
		setLoading(boolean);
	}, []);

	const emailValidation = useMemo(() => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		let isValidEmail: boolean[] = [];

		request.map(i => {
			isValidEmail.push(emailRegex.test(i.email));
		}, []);

		return isValidEmail;
	}, []);

	const isDisableButton = useMemo(() => {
		if (request.length > 0) {
			const {
				business,
				city,
				document,
				email,
				name,
				neighborhood,
				number,
				phone,
				quantityCard,
				state,
				street,
				zipcode
			} = request[0];

			if (
				!business?.cnpj ||
				!city ||
				!document ||
				!email ||
				!name ||
				!neighborhood ||
				(number !== 0 && !number) ||
				!phone ||
				!quantityCard ||
				!state ||
				!street ||
				!zipcode ||
				loading
			) {
				return true;
			}
		}

		return false;
	}, [request]);

	const editValue = useCallback(
		(index, id, value) => {
			const newRequest = request;

			if (id === "cnpj") {
				newRequest[index].business[id] = value;
				handleChange("request", newRequest);
			} else {
				if (id === "phone") {
					if (value.length > 11) {
						return;
					}

					newRequest[index][id] = value;
				} else if (id === "zipcode") {
					if (value.length > 8) {
						return;
					}
					if (value.length === 8) {
						searchZipCode(value, index);
					}
					newRequest[index][id] = value;
				} else if (id === "number") {
					newRequest[index][id] = value || 0;
				} else {
					newRequest[index][id] = value;
				}

				handleChange("request", newRequest);
			}
		},
		[handleChange, request]
	);

	const searchZipCode = useCallback(
		(zipcode, index) => {
			fetch(`https://viacep.com.br/ws/${zipcode}/json/`)
				.then(res => res.json())
				.then(json => {
					if (json?.erro) {
						toastError("CEP não encontrado, por favor preencha manualmente!");
					} else if (json) {
						editValue(index, "street", json.logradouro);
						editValue(index, "complement", json.complemento);
						editValue(index, "neighborhood", json.bairro);
						editValue(index, "city", json.localidade);
						editValue(index, "state", json.uf);
					}
					isLoading(false);
				})
				.catch(err => {
					toastError(err);
					isLoading(false);
				});
		},
		[editValue, isLoading]
	);

	const goCheck = useCallback(() => {
		if (emailValidation.find(i => !i)) {
			toastError("E-mail inválido, por favor corrija e tente novamente");
		} else {
			history.push("/RequestCards/Add/Detail");
		}
	}, [emailValidation]);

	const templateJson = useMemo(
		() => [
			{
				"Quantidade de cartões": 100,
				Logradouro: "Rua Alameda Slim",
				CEP: "89235010",
				Destinatário: "Bruno e Marrone",
				Estado: "SC",
				"Tipo de serviço": "WII001_PLUS",
				Telefone: "47999999999",
				Cidade: "São Paulo",
				Complemento: "Casa",
				"CPF ou CNPJ do destinatário": "218.881.930-69",
				Email: "brunoemarrone@gmail.com",
				Numero: 0,
				Bairro: "Centro",
				"CNPJ da empresa": "28.308.418/0001-80"
			}
		],
		[]
	);

	const addValueRequest = useCallback(() => {
		handleChange("request", [
			...request,
			{
				business: {
					cnpj: ""
				},
				quantityCard: 0,
				street: "",
				document: "",
				zipcode: "",
				name: "",
				number: 0,
				neighborhood: "",
				complement: "",
				city: "",
				state: "",
				phone: "",
				email: "",
				service: ""
			}
		]);
	}, [request, handleChange]);

	const deleteValueRequest = useCallback(index => {
		handleChange(
			"request",
			request.filter((_, ind) => ind !== index)
		);
	}, []);

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper>
					<Header>
						<Typography variant="h5">{ls.requestNewCards}</Typography>
						<RowIcons>
							<BackgroundIcon type="button" onClick={backToReport}>
								<Tooltip title="Minhas solicitações">
									<FaFileInvoice color="#5734D9" size={22} />
								</Tooltip>
							</BackgroundIcon>
							<BackgroundIcon type="button" onClick={lastRequest}>
								<Tooltip title="Refazer último pedido">
									<MdLastPage color="#5734D9" size={22} />
								</Tooltip>
							</BackgroundIcon>
							<BackgroundIcon type="button" onClick={openSelectFile}>
								<Tooltip title="Importar pedido">
									<SiGooglesheets color="#5734D9" size={22} />
								</Tooltip>
							</BackgroundIcon>
							<BackgroundIcon
								type="button"
								onClick={() =>
									exportExcelGenerate(
										templateJson,
										"Exemplo Pedido",
										"Template_Pedido"
									)
								}
							>
								<Tooltip title="Template da importação do pedido">
									<CgTemplate color="#5734D9" size={24} />
								</Tooltip>
							</BackgroundIcon>
						</RowIcons>
					</Header>

					{request.length > 0 && (
						<Body>
							{request.map((i, index) => {
								return (
									<div>
										<BackgroundHeader>
											<Title>Pedido #{index + 1} </Title>{" "}
											{request.length > 1 && (
												<button
													type="button"
													style={{
														textAlign: "center",
														color: "#494949",
														fontWeight: "bold",
														background: "none",
														border: "none"
													}}
													onClick={() => {
														deleteValueRequest(index);
													}}
												>
													x
												</button>
											)}
										</BackgroundHeader>

										<Form item={i} index={index} onChange={editValue} />
									</div>
								);
							})}
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "100%"
								}}
							>
								<ButtonRequest
									style={{ background: "none" }}
									type="button"
									onClick={addValueRequest}
								>
									<FaPlusCircle color="#5734da" />
								</ButtonRequest>
							</div>
						</Body>
					)}

					<Footer>
						<ButtonRequest
							disabled={isDisableButton}
							isDisabled={isDisableButton}
							type="button"
							onClick={goCheck}
						>
							{loading ? <Spinner color="secondary" size={16} /> : "Solicitar"}
						</ButtonRequest>
					</Footer>
				</Paper>
			</Grid>
			<input
				ref={inputFile}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFileSelected}
			/>
		</Grid>
	);
};

export default Add;
