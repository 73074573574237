import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const BATCH_BUSINESS = "[BUSINESS BATCH] BATCH_BUSINESS";
export const BATCH_BUSINESS_SUCCESS = "[BUSINESS BATCH] BATCH_BUSINESS_SUCCESS";
export const BATCH_BUSINESS_FAILED = "[BUSINESS BATCH] BATCH_BUSINESS_FAILED";

export const ADD_IMAGE_TO_BUSINESS = "[BUSINESS BATCH] ADD_IMAGE_TO_BUSINESS";
export const ADD_IMAGE_TO_BUSINESS_SUCCESS =
	"[BUSINESS BATCH] ADD_IMAGE_TO_BUSINESS_SUCCESS";
export const ADD_IMAGE_TO_BUSINESS_FAILED =
	"[BUSINESS BATCH] ADD_IMAGE_TO_BUSINESS_FAILED";

export const SET_VALUE = "[BUSINESS BATCH] SET_VALUE";
export const CLEAR_VALUES = "[BUSINESS BATCH] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export const getBatchBusiness =
	(filter?: any, callback?: Callback<any>) => async (dispatch, getState) => {
		dispatch({ type: BATCH_BUSINESS });

		try {
			const { login, businessBatch } = getState();

			const { codCli } = businessBatch;

			let response = await api.sendGet(
				`/Business/Wiipo/CodCli/${codCli}${filter || ""}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
				return dispatch(
					{
						type: BATCH_BUSINESS_SUCCESS,
						payload: result.model?.map(i => ({ ...i, checked: false }))
					},
					setValue({ loading: false })
				);
			}
			const errors = getErrors(result.errors);

			callback && callback(errors);
			return dispatch({
				type: BATCH_BUSINESS_FAILED,
				payload: errors
			});
		} catch (error) {
			return dispatch({
				type: BATCH_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};

export const createBusinessGroup =
	(callback?: Callback<any>) => async (dispatch, getState) => {
		dispatch({ type: BATCH_BUSINESS });

		try {
			const { login, businessBatch } = getState();

			const { items } = businessBatch;

			const findHeadquarters = items.find(i => i.type === 4);

			const filterItems = items.filter(i => i.checked);

			if (filterItems.length >= 2 && !findHeadquarters) {
				dispatch({
					type: BATCH_BUSINESS_FAILED,
					payload: "Por favor escolha uma matriz"
				});
				return callback && callback("Por favor escolha uma matriz");
			}

			let response = await api.sendPost("/Business/Group", filterItems, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch(
					{
						type: BATCH_BUSINESS_SUCCESS,
						payload: result.model
					},
					setValue({ loading: false })
				);

				return callback && callback(null, result.model);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: BATCH_BUSINESS_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: BATCH_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};

export const addBusinessGroup =
	(callback?: Callback<any>) => async (dispatch, getState) => {
		dispatch({ type: BATCH_BUSINESS });

		try {
			const { login, businessBatch } = getState();

			const { items, cnpjHeadquarters } = businessBatch;

			const filterItems = items.filter(i => i.checked);

			let response = await api.sendPost(
				"/Business/Group/" + cnpjHeadquarters,
				filterItems,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch(
					{
						type: BATCH_BUSINESS_SUCCESS,
						payload: result.model
					},
					setValue({ loading: false })
				);

				return callback && callback(null, result.model);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: BATCH_BUSINESS_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: BATCH_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
