import { IconButton, Tooltip } from "@material-ui/core";
import history from "AppHistory";
import TextInputGeneric from "Components/TextInputGeneric";
import { toastError } from "helpers/toast";
import _ from "lodash";
import React, { useCallback } from "react";
import { TfiReload } from "react-icons/tfi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getBatchBusiness, setValue } from "store/actions/business/createBatch";
import InfoIcon from "@material-ui/icons/Info";

import ReportBatch from "./Report";
import {
	BackgroundInput,
	ButtonAtt,
	Container,
	GoToManual,
	Label,
	Line,
	RowCodCli,
	Title
} from "./styles";
import { Row } from "Components/views/Dashboard/WiipoFlex/RequestCards/Form/styles";

let getBatchBusinessDebounced: (() => void) & _.Cancelable = null;

const CreateBatch: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const { codCli, cnpjHeadquarters, items } = useSelector<any, any>(
		s => s.businessBatch
	);

	const isHeadquarters = items?.find(i => i.content.type === 4);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const getBusiness = useCallback(() => {
		if (getBatchBusinessDebounced) {
			getBatchBusinessDebounced.cancel();
		}

		getBatchBusinessDebounced = _.debounce(() => {
			dispatch(
				getBatchBusiness(null, (err, model) => {
					if (err) toastError(JSON.stringify(err));
					if (!model)
						toastError(
							"Codcli não encontrado/errado, digite novamente ou  mude para cadastro manual"
						);
				})
			);
		}, 500);

		getBatchBusinessDebounced();
	}, [dispatch]);

	const backToManual = useCallback(() => {
		history.push(location.pathname.replace("CreateBatch", "Create"));
	}, [location.pathname]);

	return (
		<Container>
			<Title>Cadastro de empresa</Title>
			<Line />
			<RowCodCli>
				<BackgroundInput>
					<Label>CODCLI informado:</Label>
					<TextInputGeneric
						id="codCli"
						variant="filled"
						placeholder="Digite o CodCli"
						InputProps={{
							disableUnderline: true,
							style: {
								padding: 8,
								height: 48,
								width: 242,
								backgroundColor: "#fff",
								borderBlockColor: "#707070",
								borderStyle: "solid",
								borderWidth: 1,
								borderRadius: 8,
								marginRight: 5
							}
						}}
						isPadding={false}
						fullWidth={false}
						value={codCli}
						onChange={handleChange}
						disabled={false}
						size="small"
					/>
					<ButtonAtt title="Atualizar" placement="top">
						<IconButton onClick={getBusiness}>
							<TfiReload color="#fff" />
						</IconButton>
					</ButtonAtt>
				</BackgroundInput>
				<GoToManual type="button" onClick={backToManual}>
					Mudar para cadastro manual
				</GoToManual>
			</RowCodCli>
			<Row>
				<BackgroundInput>
					<Label>CNPJ da Matriz:</Label>
					<TextInputGeneric
						id="cnpjHeadquarters"
						variant="filled"
						placeholder="Digite o CNPJ da Matriz"
						InputProps={{
							disableUnderline: true,
							style: {
								padding: 8,
								height: 48,
								width: 242,
								backgroundColor: "#fff",
								borderBlockColor: "#707070",
								borderStyle: "solid",
								borderWidth: 1,
								borderRadius: 8,
								marginRight: 5
							}
						}}
						isPadding={false}
						fullWidth={false}
						value={cnpjHeadquarters}
						onChange={handleChange}
						disabled={isHeadquarters}
						size="small"
					/>
					<Tooltip
						title="Preencha este campo se deseja importar a empresa para uma matriz já existente."
						arrow
					>
						<InfoIcon
							style={{ color: "#5838d1", cursor: "pointer", marginLeft: 8 }}
						/>
					</Tooltip>
				</BackgroundInput>
			</Row>
			<ReportBatch />
		</Container>
	);
};

export default CreateBatch;
